import { React, styled } from '@sd-utility/main-dependencies';
import {
	ContentBanner1 as ContentBanner1Fuse,
	GridContainer,
	GridItem,
	GridRow,
	SpacingWrapper,
} from '@tvg/fuse-design';
import buildGoogleAnalyticsTag from '../../utils/buildGoogleAnalyticsTag';
import buttonClickHandler from '../../utils/buttonClickHandler';
import buildImageSources from '../../utils/content/buildImageSources';
import { RequestParams } from '../../utils/types';
import { Branded, ComponentRenderer, HydratableRendering } from '../types';
import { addBrandColourConfig, imageSizeConfig, variantConfig } from './prop-config';
import { ContentBanner1Component } from './types';

export const contentBanner1SchemaId =
	'https://theverygroup.com/dcx-templating/modules/content-banner-1';

const StyledGridContainer = styled(GridContainer)`
	&& {
		padding: 0;
	}
`;

export const ContentBanner1 = ({
	brand,
	variant,
	image,
	imageSize,
	heading,
	subheading,
	addBrandColour,
	switchSubHeadingPosition,
	paragraph,
	representative,
	terms,
	cta,
	params,
}: Branded<ContentBanner1Component>): JSX.Element => {
	const pageType = params?.prefix;

	return (
		<SpacingWrapper marginBottom="l">
			<StyledGridContainer>
				<GridRow>
					<GridItem xxs="12">
						<ContentBanner1Fuse
							variant={variantConfig[variant]}
							image={
								image && {
									...buildImageSources({
										image,
										templates: ['poi'],
										params: {
											aspect: imageSize ? imageSizeConfig[imageSize].aspect : '4:3',
											w: 740,
											qlt: 'default',
										},
										brand,
									}),
									size: imageSize ? imageSizeConfig[imageSize].name : 'small',
								}
							}
							textContent={{
								heading,
								headingTag: 'h2',
								subHeading: subheading,
								subHeadingTag: 'h3',
								addBrandColour: addBrandColour && addBrandColourConfig[addBrandColour],
								switchSubHeadingPosition,
								paragraph,
								representative,
								legalText: terms,
							}}
							link={{
								text: cta && cta[0].text,
								href: cta && cta[0].url,
								onClick:
									cta &&
									cta[0].publishEvent &&
									(() => buttonClickHandler(`${cta[0].publishEvent}`)),
							}}
							secondaryLink={{
								text: cta && cta.length === 2 && cta[1].text,
								href: cta && cta.length === 2 && cta[1].url,
								onClick:
									cta &&
									cta.length === 2 &&
									cta[1].publishEvent &&
									(() => buttonClickHandler(`${cta[1].publishEvent}`)),
							}}
							lLayout={pageType === 'standard' ? 'v1' : 'v2'}
							dataAnalyticsId={
								cta &&
								buildGoogleAnalyticsTag(
									'ContentBanner1',
									(heading && cta.length === 2 && `${heading} - ${cta[0].text}`) ||
										heading ||
										(cta && cta[0].text) ||
										'',
								)
							}
							dataAnalyticsIdSecondary={
								cta &&
								cta.length === 2 &&
								buildGoogleAnalyticsTag(
									'ContentBanner1',
									(heading && `${heading} - ${cta && cta[1].text}`) || (cta && cta[1].text) || '',
								)
							}
						/>
					</GridItem>
				</GridRow>
			</StyledGridContainer>
		</SpacingWrapper>
	);
};

export const contentBanner1Renderer: ComponentRenderer<ContentBanner1Component> = (
	props: ContentBanner1Component,
	brand: string,
	params?: RequestParams,
): HydratableRendering => ({
	component: <ContentBanner1 {...props} brand={brand} params={params} />,
	props: { ...props, brand },
	reference: 'ContentBanner1',
});
