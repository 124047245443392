import { React } from '@sd-utility/main-dependencies';
import {
	GridContainer,
	GridItem,
	GridRow,
	SpacingWrapper,
	TextBlock as TextBlockFuse,
	gridConfig,
} from '@tvg/fuse-design';
import styled from 'styled-components';
import richTextEditor from '../../utils/rich-text-editor/richTextEditor';
import { ComponentRenderer, HydratableRendering } from '../types';
import backgroundConfig from './backgroundConfig';
import { TextBlockComponent } from './types';

export const textBlockSchemaId = 'https://theverygroup.com/dcx-templating/modules/text-block';

// Equivalent to 10 columns. Required to keep text block background colour full width but content inside to reduce to 10 columns.
const customWidth = (10 / 12) * 100;

const StyledGridItem = styled(GridItem)`
	&& {
		.fuse-text-block {
			> * {
				@media only screen and (min-width: ${gridConfig.l.minWidth}) {
					max-width: ${customWidth}%;
				}
			}

			.fuse-text-block--center-align {
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
`;

export const TextBlock = ({
	background,
	blackBorder = false,
	gridWrapper = true,
	paddingTop = false,
	paddingBottom = false,
	paddingLeft = false,
	paddingRight = false,
	text,
}: TextBlockComponent): JSX.Element => {
	const TextBlock = (
		<TextBlockFuse
			background={background && backgroundConfig[background].propValue}
			paddingTop={paddingTop}
			paddingBottom={paddingBottom}
			paddingLeft={paddingLeft}
			paddingRight={paddingRight}
			blackBorder={blackBorder}
			dangerouslySetInnerHTML={{
				__html: richTextEditor(text, background),
			}}
		/>
	);

	return gridWrapper ? (
		<SpacingWrapper marginBottom="l">
			<GridContainer>
				<GridRow>
					<StyledGridItem xxs="12">{TextBlock}</StyledGridItem>
				</GridRow>
			</GridContainer>
		</SpacingWrapper>
	) : (
		TextBlock
	);
};

export const textBlockRenderer: ComponentRenderer<TextBlockComponent> = (
	props: TextBlockComponent,
): HydratableRendering => ({
	component: <TextBlock {...props} />,
	props,
	reference: 'TextBlock',
});
