import { React, styled } from '@sd-utility/main-dependencies';
import { Accordion as AccordionFuse, GridContainer, GridItem, GridRow } from '@tvg/fuse-design';
import { TextBlock } from '../text-block/text-block';
import { Branded, ComponentRenderer, HydratableRendering } from '../types';
import { AccordionComponent } from './types';

export const accordionSchemaId = 'https://theverygroup.com/dcx-templating/modules/accordion-seo';

const StyledAccordion = styled(AccordionFuse)`
	&& .fuse-text-block {
		padding-top: var(--spacing-global-space5);
	}
`;

export const Accordion = ({ title, body, ...props }: Branded<AccordionComponent>): JSX.Element => {
	return (
		<GridContainer>
			<GridRow>
				<GridItem xxs="12">
					<StyledAccordion
						chevronInline
						heading={title}
						headingTag="h1"
						titleSize={{
							xxs: 'small',
							m: 'medium',
							l: 'xlarge',
						}}
						hideBorder
						open={false}
					>
						<TextBlock
							className="fuse-accordion-text-block"
							gridWrapper={false}
							text={body.body.replace(
								/<span class="fuse-text-block__text-size-3"/g,
								'## <span class="fuse-text-block__text-size-3"',
							)}
							{...props}
						/>
					</StyledAccordion>
				</GridItem>
			</GridRow>
		</GridContainer>
	);
};

export const accordionRenderer: ComponentRenderer<AccordionComponent> = (
	props: AccordionComponent,
	brand: string,
): HydratableRendering => ({
	component: <Accordion {...props} brand={brand} />,
	props: { ...props, brand },
	reference: 'Accordion',
});
