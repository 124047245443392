import { React, styled } from '@sd-utility/main-dependencies';
import {
	CardGroup as CardGroupFuse,
	ContentCard,
	GridContainer,
	GridItem,
	GridRow,
	SpacingWrapper,
	gridConfig,
} from '@tvg/fuse-design';
import buildGoogleAnalyticsTag from '../../utils/buildGoogleAnalyticsTag';
import buildImageSources from '../../utils/content/buildImageSources';
import { RequestParams } from '../../utils/types';
import { Branded, ComponentRenderer, HydratableRendering } from '../types';
import { VideoPlayer } from '../video-player/video-player';
import { CardGroupComponent } from './types';

export const cardGroupSchemaId = 'https://theverygroup.com/dcx-templating/modules/card-group';

export const CardGroup = ({
	brand,
	heading: title,
	description,
	cards,
	cardsImageRatio,
	cardGroupLayout,
	params,
}: Branded<CardGroupComponent>): JSX.Element => {
	const StyledGridContainer = styled(GridContainer)`
		&& {
			@media only screen and (min-width: ${gridConfig.l.minWidth}) {
				${({ pageType }) => pageType !== 'standard' && `padding: 0;`}
			}
		}
	`;

	const cardGroupLayoutConfig: {
		[key: string]: { xxsColumns: number; mColumns: number; lColumns: number };
	} = {
		'Mobile: 1, Tablet: 2, Desktop: 2': {
			xxsColumns: 1,
			mColumns: 2,
			lColumns: 2,
		},
		'Mobile: 1, Tablet: 3, Desktop: 3': {
			xxsColumns: 1,
			mColumns: 3,
			lColumns: 3,
		},
		'Mobile: 2, Tablet: 4, Desktop: 4': {
			xxsColumns: 2,
			mColumns: 4,
			lColumns: 4,
		},
	};

	const hasVideo = cards.some((card) => card.video);
	const pageType = params?.prefix;

	return (
		<SpacingWrapper marginBottom="l">
			<StyledGridContainer pageType={pageType}>
				<GridRow>
					<GridItem xxs="12">
						<CardGroupFuse
							title={title}
							description={description}
							titleTag="h2"
							xxsColumns={
								hasVideo ? 1 : cardGroupLayout && cardGroupLayoutConfig[cardGroupLayout].xxsColumns
							}
							mColumns={
								hasVideo ? 2 : cardGroupLayout && cardGroupLayoutConfig[cardGroupLayout].mColumns
							}
							lColumns={
								hasVideo ? 2 : cardGroupLayout && cardGroupLayoutConfig[cardGroupLayout].lColumns
							}
						>
							{cards.map(({ heading, body, image, cta, ctas, video }, i) => (
								<ContentCard
									key={i}
									heading={heading}
									headerTag={title ? 'h3' : 'h2'}
									body={body}
									image={
										image && {
											...buildImageSources({
												image,
												templates: ['poi'],
												params: {
													aspect: cardsImageRatio || '1:1',
													w: 900,
													qlt: 'default',
												},
												brand,
											}),
											alt: '',
										}
									}
									videoPlayerSlot={
										video && (
											<VideoPlayer
												brand={brand}
												gridWrapper={false}
												enableText={false}
												ratio={cardsImageRatio}
												{...video}
											/>
										)
									}
									aspectRatio={cardsImageRatio}
									link={{
										text: (ctas && ctas[0].text) || (cta && cta.text) || undefined,
										href: (ctas && ctas[0].url) || (cta && cta.url) || undefined,
									}}
									secondaryLink={{
										text: (ctas && ctas.length === 2 && ctas[1].text) || undefined,
										href: (ctas && ctas.length === 2 && ctas[1].url) || undefined,
									}}
									dataAnalyticsId={
										ctas && ctas[0].url
											? buildGoogleAnalyticsTag(
													'CardGroup',
													(heading && ctas[0].text && `${heading} - ${ctas[0].text}`) ||
														ctas[0].text ||
														heading ||
														'',
													i,
											  )
											: cta && cta.url
											? buildGoogleAnalyticsTag(
													'CardGroup',
													(heading && cta.text && `${heading} - ${cta.text}`) ||
														cta.text ||
														heading ||
														'',
													i,
											  )
											: undefined
									}
									dataAnalyticsIdSecondary={
										ctas && ctas.length === 2 && ctas[1].text && ctas[1].url
											? buildGoogleAnalyticsTag(
													'CardGroup',
													(heading && `${heading} - ${ctas[1].text}`) || ctas[1].text,
													i,
											  )
											: undefined
									}
									removeMargin={true}
								/>
							))}
						</CardGroupFuse>
					</GridItem>
				</GridRow>
			</StyledGridContainer>
		</SpacingWrapper>
	);
};

export const cardGroupRenderer: ComponentRenderer<CardGroupComponent> = (
	props: CardGroupComponent,
	brand: string,
	params?: RequestParams,
): HydratableRendering => ({
	component: <CardGroup {...props} brand={brand} params={params} />,
	props: { ...props, brand },
	reference: 'CardGroup',
});
